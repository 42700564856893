











import { Component, Vue } from 'vue-property-decorator';
import MundoBancanteHeader from '../components/MundoBancante/MundoBancanteHeader.vue';
import TextMedium from '../components/Text/TextMedium.vue';

@Component({
  components: {
    MundoBancanteHeader,
    TextMedium,
  },
})
export default class EmailSent extends Vue {}
